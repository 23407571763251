export default {
  init() {
    // JavaScript to be fired on all pages
    const menu = document.querySelector('nav.nav-primary');

    function toggleMenuVisibility(evt) {
      evt.preventDefault();
      evt.stopPropagation();

      if (!menu) {
        return;
      }

      menu.classList.toggle('is-open');
    }

    function closeMenu(evt) {
      if (evt.target.closest('.nav-primary')) {
        return;
      }

      menu.classList.remove('is-open');
    }

    const menuButton = document.querySelector('.header__menu-button');

    if (menuButton) {
      menuButton.addEventListener('click', toggleMenuVisibility)
    }

    document.documentElement.addEventListener('click', closeMenu);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
