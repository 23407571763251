export default {
  init() {
    // document.body.style.paddingTop = `${document.querySelector('header.banner').clientHeight}px`;

    $('.testimonials__list').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    if (!$('.hero.hero--quote .vce-hero-section--wrap-row video').length) {
      $('.hero.hero--quote .vce-hero-section--wrap-row').prepend('<video muted autoplay playsinline loop><source type="video/mp4" src="/wp-content/uploads/2022/10/Jack-The-Lawyer_HOME_Low-low-Res.mp4"></video>');
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
